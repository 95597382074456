export const dealPagesMap = {
    info: {
        label: 'Datos cliente',
    },
    notes: {
        label: 'Notas',
    },
    chat: {
        label: 'Mensajes entrantes',
    },
    offer: {
        label: 'Oferta',
    },
    submissions: {
        label: 'Datos financiación',
    },
    vehicle: {
        label: 'Datos vehículo',
    },
    documentation: {
        label: 'Documentación',
    },
    'amount-breakdown': {
        label: 'Desglose importes',
    },
    delivery: {
        label: 'Entrega',
    },
    renting: {
        label: 'Renting',
    },

    marketing: {
        label: 'Datos marketing',
    },
    alerts: {
        label: 'Alertas',
    },

    asnef: {
        label: 'Asnef',
    },
    aggregations: {
        label: 'Agregación Bancaria (PSD2)',
    },
    // 'activity-log': {
    //     label: 'Registro de actividad',
    // },
};
