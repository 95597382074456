export const vehiclePagesMap = {
    info: {
        label: 'Datos vehículo',
    },
    notes: {
        label: 'Notas',
    },
    chat: {
        label: 'Mensajes entrantes',
    },
    gallery: {
        label: 'Galería',
    },
    documents: {
        label: 'Documentos',
    },
    repricing: {
        label: 'Registro de precios',
    },
    'status-transitions': {
        label: 'Registro de cambios',
    },
    marketing: {
        label: 'Datos marketing',
    },
    'associated-leads': {
        label: 'Leads asociados',
    },
};
