export const clientPagesMap = {
    info: {
        label: 'Datos cliente',
    },
    associatedDeals: {
        label: 'Leads de ventas',
    },
    associatedVehicles: {
        label: 'Leads de compras',
    },
    documents: {
        label: 'Documentación',
    },
};
