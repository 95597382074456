import { useRouter } from 'next/router';
import { CatalogIcon, ClientsIcon, DealsIcon } from '@/components/icons';
import { Breadcrumb } from '@/components/Breadcrumb/Breadcrumb';
import { dealPagesMap } from '@/layouts/DealDetailLayout/deal-pages-map';
import { clientPagesMap } from '@/layouts/ClientDetailLayout/pages-map';
import { Header } from '@/components/Header/Header';
import { vehiclePagesMap } from '@/layouts/VehicleDetailLayout/vehicles-pages-map';

const sectionsMap = {
    deals: {
        icon: <DealsIcon />,
        label: 'Ventas',
        href: '/deals',
        pages: dealPagesMap,
    },
    vehicles: {
        icon: <CatalogIcon />,
        label: 'Compras',
        href: '/vehicles',
        pages: vehiclePagesMap,
    },
    clients: {
        icon: <ClientsIcon />,
        label: 'Clientes',
        href: '/clients',
        pages: clientPagesMap,
    },
};

export function GlobalBreadcrumb() {
    const router = useRouter();
    const generatePathParts = (pathStr: string) => {
        const pathWithoutQuery = pathStr.split('?')[0];
        return pathWithoutQuery.split('/').filter((v) => v.length > 0);
    };

    const route = generatePathParts(router.asPath);
    const section = sectionsMap[route[0] as keyof typeof sectionsMap];

    if (route.length <= 1 || !section) {
        return null;
    }

    const page = section.pages[route[2] as keyof typeof section.pages];

    return (
        <Header>
            <Breadcrumb>
                <Breadcrumb.Item key={section.href} href={section.href}>
                    <div className="flex hover:underline font-light">
                        <div className="flex items-center h-5 w-5 mr-1.5 text-blue-500">{section.icon}</div>
                        {section.label}
                    </div>
                </Breadcrumb.Item>
                {page && (
                    <Breadcrumb.Item key={page.label}>
                        <span className="text-gray-600">{page.label}</span>
                    </Breadcrumb.Item>
                )}
            </Breadcrumb>
        </Header>
    );
}
