import { GlobalBreadcrumb } from '@/components/Breadcrumb/GlobalBreadcrumb';
import { useDialog } from '@/components/Dialog/DialogHandler';
import { Sidebar } from '@/components/Sidebar';
import { HelperBot } from '@/components/Widget';
import { CatalogIcon, ConnectIconName, DashboardIcon, DealsIcon, OperationsIcon } from '@/components/icons';
import { ClientsIcon } from '@/components/icons/Navigation/ClientsIcon';
import { UsersIcon } from '@/components/icons/Navigation/UsersIcon';
import { Roles, roles, useProfileStore } from '@/stores';
import { SeeAsRoleDialog } from '@/views/config';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { PropsWithChildren } from 'react';
import { HiChevronDown, HiX } from 'react-icons/hi';

const logos = {
    icon: '/images/isologo.svg',
    name: <ConnectIconName />,
};

const PAGES_TITLE = {
    '/': () => 'Dashboard',
    '/deals': () => 'Deals',
    '/operations': () => 'Financiaciones',
    '/clients': () => 'Clientes',
    '/vehicles': () => 'Catálogo',
    '/users': () => 'Usuarios',
    '/configuration': () => 'Configuración',
    '/deals/[uuid]/offer': (dealUuid: string) => `Deal ${dealUuid}`,
    '/deals/[uuid]/delivery': (dealUuid: string) => `Deal ${dealUuid}`,
    '/deals/[uuid]/documentation': (dealUuid: string) => `Deal ${dealUuid}`,
    '/deals/[uuid]/amount-breakdown': (dealUuid: string) => `Deal ${dealUuid}`,
    '/deals/[uuid]/vehicle': (dealUuid: string) => `Deal ${dealUuid}`,
    '/deals/[uuid]/marketing': (dealUuid: string) => `Deal ${dealUuid}`,
    '/deals/[uuid]/alerts': (dealUuid: string) => `Deal ${dealUuid}`,
    '/deals/[uuid]/notes': (dealUuid: string) => `Deal ${dealUuid}`,
    '/deals/[uuid]/info': (dealUuid: string) => `Deal ${dealUuid}`,
    '/deals/[uuid]/carfax': (dealUuid: string) => `Deal ${dealUuid}`,
    '/deals/[uuid]/asnef': (dealUuid: string) => `Deal ${dealUuid}`,
    '/deals/[uuid]/aggregations': (dealUuid: string) => `Deal ${dealUuid}`,
    '/deals/[uuid]/submissions': (dealUuid: string) => `Deal ${dealUuid}`,
    '/deals/[uuid]/submissions/[id]': (dealUuid: string, submissionUuid: string) => `Submission ${submissionUuid}`,
    '/clients/[uuid]/info': (clientUuid: string) => `Cliente ${clientUuid}`,
};

export function MainLayout({
    children,
    className,
}: PropsWithChildren<{
    className?: string;
}>) {
    const dialog = useDialog();
    const router = useRouter();
    const clibotDisabled = true;
    const { testRole, setTestRole, isAdmin, hasRequiredRoles } = useProfileStore();

    const getTitle = () => {
        const environment = process.env.NEXT_PUBLIC_SITE_URL !== 'https://connect.clidrive.com' ? 'STG ' : '';
        const baseTitle = `${environment}Connect  - `;
        const path = router.pathname as keyof typeof PAGES_TITLE;
        const params = Object.values(router.query);

        const pageTitle: Function = PAGES_TITLE[path] || (() => null);

        return pageTitle(...params) === null ? `${environment}Connect` : `${baseTitle} ${pageTitle(...params)}`;
    };

    const Navigation = [
        {
            label: 'Panel Principal',
            icon: DashboardIcon,
            pathname: '/',
            disabled: hasRequiredRoles([Roles.EXTERNAL_AGENT], false),
        },
        {
            label: 'Negocios',
            icon: DealsIcon,
            pathname: '/deals',
        },
        {
            label: 'Financiaciones',
            icon: OperationsIcon,
            pathname: '/operations',
            disabled: hasRequiredRoles([Roles.EXTERNAL_AGENT], false),
        },
        {
            label: 'Clientes',
            icon: ClientsIcon,
            pathname: '/clients',
            disabled: !hasRequiredRoles([
                Roles.FINANCIAL_ANALYST,
                Roles.FINANCE_USER,
                Roles.C2C_SALES_AGENT,
                Roles.CERTIFIER,
                Roles.COLLECTION_AGENT,
                Roles.APPRAISER,
                Roles.DELIVERIES,
                Roles.PROCESSOR,
                Roles.MARKETING_AGENT,
            ]),
        },
        {
            label: 'Catálogo',
            icon: CatalogIcon,
            pathname: '/vehicles',
            disabled: !hasRequiredRoles([
                Roles.APPRAISER,
                Roles.PROCESSOR,
                Roles.DELIVERIES,
                Roles.C2C_SALES_AGENT,
                Roles.MARKETING_AGENT,
                Roles.EXTERNAL_AGENT,
            ]),
        },
        {
            label: 'Usuarios',
            icon: UsersIcon,
            pathname: '/users',
            disabled: !isAdmin(),
        },
    ];

    const handleOpenSeeAsRoleDialog = () => {
        dialog({
            type: 'modal',
            content: <SeeAsRoleDialog />,
        });
    };

    const handleRemoveTestRole = () => {
        setTestRole(undefined);
    };

    return (
        <>
            <Head>
                <title>{getTitle()}</title>
            </Head>
            <div className="flex h-screen overflow-hidden bg-gray-200">
                <Sidebar logo={logos} items={Navigation} />
                <section className="flex flex-1 flex-col">
                    {testRole && (
                        <div className="flex gap-3 justify-center items-center bg-blue-500 text-white text-sm font-medium">
                            Estás viendo CONNECT como
                            <button
                                className="flex h-full ps-2 pe-1 items-center gap-1 bg-gray-800/20 font-bold cursor-pointer"
                                onClick={handleOpenSeeAsRoleDialog}
                            >
                                {roles[testRole].toUpperCase()}
                                <HiChevronDown />{' '}
                            </button>
                            <button
                                className="flex p-1 my-1 rounded-md bg-red-500 cursor-pointer"
                                onClick={handleRemoveTestRole}
                            >
                                <HiX className="h-3 w-3" />
                            </button>
                        </div>
                    )}

                    <GlobalBreadcrumb />
                    <main className={`h-full overflow-auto ${className ?? ''}`}>{children}</main>
                </section>
                {!clibotDisabled && <HelperBot />}
            </div>
        </>
    );
}
